import React, { createContext, useCallback, useEffect, useState } from "react"
import Projects from "./projects"
import { Footer } from "./footer"
import moment from "moment"
import ZoomModal from "./zoom-modal"
import EditOverlay from "./edit/edit-overlay"
import { Session } from "./session"

export const TimeContext = createContext(0)

declare global {
  interface Window {
    mockClock: (time: string) => void
    showClock: () => void
  }
}

function App() {
  const loadSessions = useCallback(async function loadSessions() {
    const response = await fetch("/data/30113/sessions.json", {
      cache: "no-cache",
    })
    if (response.ok) {
      const sessions = (await response.json()) as Session[]
      setSessions(sessions)
    }
    setTimeout(loadSessions, 15000)
  }, [])

  const [time, setTime] = useState(Date.now)
  const [offset, setOffset] = useState(0)
  const [zoomLink, setZoomLink] = useState<string | undefined>(undefined)
  const [showClock, setShowClock] = useState(false)

  const [sessions, setSessions] = useState<Session[]>()

  useEffect(() => {
    loadSessions()
  }, [])

  function mockClock(time: string) {
    setOffset(moment(`2023-01-19T${time}+02:00`).unix() * 1000 - Date.now())
  }

  window.mockClock = mockClock
  window.showClock = () => setShowClock(true)

  useEffect(() => {
    const ticker = setInterval(() => {
      setTime(Date.now() + offset)
    }, 1000)

    function getOffset() {
      fetch("https://worldtimeapi.org/api/timezone/Europe/Berlin.json", {
        cache: "no-cache",
      })
        .then((response) => response.json())
        .then((data) => data.unixtime)
        .then((os) => setOffset(os * 1000 - Date.now()))
    }

    getOffset()
    const offseter = setInterval(getOffset, 15 * 60 * 1000)

    return () => {
      clearInterval(ticker)
      clearInterval(offseter)
    }
  }, [])

  return (
    <>
      <EditOverlay reloadSessions={loadSessions} />
      <TimeContext.Provider value={time + offset}>
        {zoomLink && (
          <ZoomModal link={zoomLink} onClose={() => setZoomLink(undefined)} />
        )}

        <div className="lg:mx-12 2xl:container 2xl:mx-auto">
          {showClock && (
            <div className="text-blue-100 font-bold fixed bg-black p-2 -ml-10 z-50 border-blue-400 border-opacity-50 border-2 rounded-lg m-5 bg-opacity-50">
              {moment(time + offset).format("DD.MM.YYYY - HH:mm:ss")} ({offset})
            </div>
          )}
          <header className="lg:rounded-b-lg overflow-hidden relative aspect-square sm:aspect-auto md:pb-1/2 mb-10">
            <img
              className="absolute w-full h-full object-cover"
              src="img/leo.jpg"
              alt="Titelbild"
            />

            <div className="absolute right-0 bottom-0 top-0 w-full">
              <h1 className="float-right mt-1/12 sm:mt-1/12 md:mt-1/12 lg:mt-1/12 text-3xl sm:text-4xl md:text-5xl lg:text-6xl right-0 m-3 md:m-10 leading-none">
                <b>DEIN</b> SOZIALES <br /> JAHR IM AUSLAND.
              </h1>

              <div className="text-left absolute right-0 sm:mb-1/12 bottom-0 clear-both sm:float-right sm:w-auto">
                <div className="bg-pink-600 text-gray-100 pl-5 pr-5 p-2 mx-0 mb-5 w-full">
                  <p className="text-xs w-full sm:text-base md:text-lg lg:text-xl xl:text-2xl font-extrabold">
                    Infotag 27.09.2025 - 11:00 – 14:30 Uhr
                  </p>
                  <h1 className="sm:block text-xs sm:text-xs md:text-sm lg:text-base font-bold">
                    Hof 7 &ensp; | &ensp; 52062 Aachen
                  </h1>
                </div>
              </div>
            </div>
          </header>

          <main className="grid grid-flow-row gap-0 ">
            <div className="lg:rounded overflow-hidden mt-2 bg-gray-100 mb-4 shadow-lg">
              <h1 className="w-full bg-pink-600 pb-1 pt-2 pl-6 text-white font-bold text-xl tracking-wider shadow-md">
                SDFV Infotag
              </h1>
              <div className="px-6 md:px-16 pb-6 text-justify mt-4 font-normal lg:col-count-2 col-gap-12">
                <video
                  src="img/infotag.m4v"
                  poster="img/standbild.jpg"
                  className="my-5 mx-auto rounded-md overflow-hidden"
                  autoPlay
                  playsInline
                  muted
                  loop
                />
                <p>
                  Der{" "}
                  <a
                    className="text-teal-500 font-bold"
                    href="https://www.fsd-aachen.de/ausland"
                  >
                    Trägerkreis des Sozialen Dienstes für Frieden und Versöhnung
                  </a>{" "}
                  ist ein Zusammenschluss von acht kleinen Organisationen, die
                  zusammen jährlich etwa 20 weltwärts-Freiwillige ins Ausland
                  entsenden.
                </p>
                <p>
                  Jedes Jahr im September veranstalten wir einen Infotag im
                  Zentrum von Aachen. Hier hast Du die Chance die einzelnen
                  Organisationen und Einsatzstellen kennenzulernen und auch mit
                  ehemaligen Freiwilligen in Kontakt zu kommen. Nutze den Tag
                  und finde heraus, welches das richtige Projekt für Dich ist.
                  Der Infotag in Aachen findet am{" "}
                  <strong> 27. September von 11:00 bis 14:30 am Hof </strong> ( Hof 7 | 52062 Aachen) statt.
                </p>
              </div>
            </div>

            <div className="lg:rounded overflow-hidden mt-2 bg-gray-100 mb-4 shadow-lg">
              <h1 className="w-full bg-pink-600 pb-1 pt-2 pl-6 text-white font-bold text-xl tracking-wider shadow-md">
                Einsatzstellen
              </h1>
              <div className="px-6 md:px-16 pb-6 text-justify mt-4 font-normal">
                <p>
                  Auf der folgenden Karte kannst Du sehen, in welchen Ländern
                  sich unsere Einsatzstellen befinden. Wenn Du nähere
                  Informationen zu den einzelnen Einsatzstellen wünschst, kannst
                  Du mit einem Klick auf das Logo die Homepage der jeweiligen
                  Entsendeorganisation erreichen.
                </p>
                <p className="pt-2">
                  Weiter Informationen findest Du auf unsere Homepage{" "}
                  <a
                    className="text-teal-500 font-bold"
                    href="https://www.fsd-aachen.de/ausland"
                  >
                    www.fsd-aachen.de/ausland
                  </a>
                  . Außerdem kannst Du Dich auch mit allen Fragen direkt an uns
                  wenden:{" "}
                  <a
                    className="text-teal-500 font-bold"
                    href="mailto:Uta.Hillermann@fsd-bistum-aachen.de"
                  >
                    Uta.Hillermann@fsd-bistum-aachen.de
                  </a>
                </p>
              </div>
              <div className="px-2">
                <Projects />
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </TimeContext.Provider>
    </>
  )
}

export default App
